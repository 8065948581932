import React from "react"
import { useTranslation } from "react-i18next"
import Add from "react-ionicons/lib/IosAddCircleOutline"
import Remove from "react-ionicons/lib/IosRemoveCircleOutline"
import { Parallax, Background } from "react-parallax"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

const CompareServices = () => {
  const { t } = useTranslation()
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "QUEUE-ISO-Dark2.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fixed(width: 2750) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `)
  return (
    <>
      <Parallax strength={100} style={{ paddingTop: 50, paddingBottom: 50 }}>
        <Background>
          <Img fixed={data.background.childImageSharp.fixed} />
        </Background>
        <div className="container">
          <div className="row">
            <div
              className="col-sm-12 darko-bg"
              style={{ borderRadius: 75, paddingTop: 30 }}
            >
              <div className="heading-title white">
                <h3 className="title iq-tw-7">{t("compare")}</h3>
              </div>
            </div>
          </div>
          <div
            className="row darko-bg"
            style={{ borderRadius: 75, paddingTop: 30 }}
          >
            <div className="col-sm-5 rounded">
              <div className="iq-services-box text-left iq-font-white">
                <div className="iq-icon">
                  <i aria-hidden="true">
                    <Add
                      color="#f0b660"
                      style={{
                        width: 40,
                        height: 40,
                      }}
                    />
                  </i>
                </div>
                <div className="services-content">
                  <h5 className="iq-tw-6 iq-pb-10 iq-font-white">
                    {t("q-pos-label")}
                  </h5>
                  <p>{t("q-pos")}</p>
                </div>
              </div>
            </div>
            <div className="col-sm-2 align-self-center text-center">
              <h2 className="iq-font-white">VS</h2>
            </div>
            <div className="col-sm-5">
              <div className="iq-services-box text-left iq-font-white">
                <div className="iq-icon">
                  <i aria-hidden="true">
                    <Remove color="#f0b660" style={{ width: 40, height: 40 }} />
                  </i>
                </div>
                <div className="services-content">
                  <h5 className="iq-tw-6 iq-pb-10 iq-font-white">
                    {t("c-pos-label")}
                  </h5>
                  <p>{t("c-pos")}</p>
                </div>
              </div>
            </div>
          </div>
          <div style={{ paddingBottom: 10 }} />
          <div
            className="row darko-bg"
            style={{ borderRadius: 75, paddingTop: 30 }}
          >
            <div className="col-sm-5">
              <div className="iq-services-box text-left iq-font-white">
                <div className="iq-icon">
                  <i aria-hidden="true">
                    <Add color="#f0b660" style={{ width: 40, height: 40 }} />
                  </i>
                </div>
                <div className="services-content">
                  <h5 className="iq-tw-6 iq-pb-10 iq-font-white">
                    {t("q-features-label")}
                  </h5>
                  <p>{t("q-features")}</p>
                </div>
              </div>
            </div>
            <div className="col-sm-2 align-self-center text-center">
              <h2 className="iq-font-white">VS</h2>
            </div>
            <div className="col-sm-5">
              <div className="iq-services-box text-left iq-font-white">
                <div className="iq-icon">
                  <i aria-hidden="true">
                    <Remove color="#f0b660" style={{ width: 40, height: 40 }} />
                  </i>
                </div>
                <div className="services-content">
                  <h5 className="iq-tw-6 iq-pb-10 iq-font-white">
                    {t("c-features-label")}
                  </h5>
                  <p>{t("c-features")}</p>
                </div>
              </div>
            </div>
          </div>
          <div style={{ paddingBottom: 10 }} />
          <div
            className="row darko-bg"
            style={{ borderRadius: 75, paddingTop: 30 }}
          >
            <div className="col-sm-5">
              <div className="iq-services-box text-left iq-font-white">
                <div className="iq-icon">
                  <i aria-hidden="true">
                    <Add color="#f0b660" style={{ width: 40, height: 40 }} />
                  </i>
                </div>
                <div className="services-content">
                  <h5 className="iq-tw-6 iq-pb-10 iq-font-white">
                    {t("q-einvoice-label")}
                  </h5>
                  <p>{t("q-einvoice")}</p>
                </div>
              </div>
            </div>
            <div className="col-sm-2 align-self-center text-center">
              <h2 className="iq-font-white">VS</h2>
            </div>
            <div className="col-sm-5">
              <div className="iq-services-box text-left iq-font-white">
                <div className="iq-icon">
                  <i aria-hidden="true">
                    <Remove color="#f0b660" style={{ width: 40, height: 40 }} />
                  </i>
                </div>
                <div className="services-content">
                  <h5 className="iq-tw-6 iq-pb-10 iq-font-white">
                    {t("c-einvoice-label")}
                  </h5>
                  <p>{t("c-einvoice")}</p>
                </div>
              </div>
            </div>
          </div>
          <div style={{ paddingBottom: 10 }} />
          <div
            className="row darko-bg"
            style={{ borderRadius: 75, paddingTop: 30 }}
          >
            <div className="col-sm-5">
              <div className="iq-services-box text-left iq-font-white">
                <div className="iq-icon">
                  <i aria-hidden="true">
                    <Add color="#f0b660" style={{ width: 40, height: 40 }} />
                  </i>
                </div>
                <div className="services-content">
                  <h5 className="iq-tw-6 iq-pb-10 iq-font-white">
                    {t("q-add-label")}
                  </h5>
                  <p>{t("q-add")}</p>
                </div>
              </div>
            </div>
            <div className="col-sm-2 align-self-center text-center">
              <h2 className="iq-font-white">VS</h2>
            </div>
            <div className="col-sm-5">
              <div className="iq-services-box text-left iq-font-white">
                <div className="iq-icon">
                  <i aria-hidden="true">
                    <Remove color="#f0b660" style={{ width: 40, height: 40 }} />
                  </i>
                </div>
                <div className="services-content">
                  <h5 className="iq-tw-6 iq-pb-10 iq-font-white">
                    {t("c-add-label")}
                  </h5>
                  <p>{t("c-add")}</p>
                </div>
              </div>
            </div>
          </div>
          <div style={{ paddingBottom: 10 }} />
          <div
            className="row darko-bg"
            style={{ borderRadius: 75, paddingTop: 30 }}
          >
            <div className="col-sm-5">
              <div className="iq-services-box text-left iq-font-white">
                <div className="iq-icon">
                  <i aria-hidden="true">
                    <Add color="#f0b660" style={{ width: 40, height: 40 }} />
                  </i>
                </div>
                <div className="services-content">
                  <h5 className="iq-tw-6 iq-pb-10 iq-font-white">
                    {t("q-price-label")}
                  </h5>
                  <p>{t("q-price")}</p>
                </div>
              </div>
            </div>
            <div className="col-sm-2 align-self-center text-center">
              <h2 className="iq-font-white">VS</h2>
            </div>
            <div className="col-sm-5">
              <div className="iq-services-box text-left iq-font-white">
                <div className="iq-icon">
                  <i aria-hidden="true">
                    <Remove color="#f0b660" style={{ width: 40, height: 40 }} />
                  </i>
                </div>
                <div className="services-content">
                  <h5 className="iq-tw-6 iq-pb-10 iq-font-white">
                    {t("c-price-label")}
                  </h5>
                  <p>{t("c-price")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Parallax>
    </>
  )
}

export default CompareServices
