import React from "react"
import "../static/style.css"
import Navbar from "../components/Navbar"
import Home from "../components/Home"
import About from "../components/About"
import Features from "../components/Features"
import Testimonial2 from "../components/Testimonial2"
import Clients2 from "../components/Clients2"
import Pricing from "../components/Pricing"
import CompareService from "../components/CompareService"
import Contact from "../components/Contact"
import Footer from "../components/Footer"
import Faq2 from "../components/Faq2"
import Messenger from "../components/Messenger"
import { Helmet } from "react-helmet"
import Seo from "../components/Seo"
import { Router, Link } from "@reach/router"
// import Map from "../components/Map"
import "bootstrap/dist/css/bootstrap.min.css"
import { I18nextProvider } from "react-i18next"
import i18n from "../i18n/config"
import Help from "./help"
import Print from "./print"
import Internet from "./internet"
import Settings from "./settings"
import Hardware from "./hardware"

const IndexPage = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <Helmet>
        <title>QUEUE Technology</title>
        <meta name="keywords" content="HTML5 Template" />
        <meta name="description" content="QUEUE Technology" />
        <meta name="author" content="QUEUE Technology" />
        <link rel="shortcut icon" href="./static/assets/images/favicon.ico" />
      </Helmet>
      <Navbar />
      <div>
        <Router>
          <Help path="/help/" />
          <Print path="/print/" />
          <Internet path="/internet/" />
          <Settings path="/settings/" />
        </Router>
      </div>
      <Home />
      <div className="main-content">
        <Seo title="QUEUE POS" />
        <About />
        <Features />
        <Clients2 />
        <Testimonial2 />
        <Pricing />
        <CompareService />
        <Faq2 />
      </div>
      <footer>
        <Contact />
        <Footer />
        <Messenger />
      </footer>
    </I18nextProvider>
  )
}

export default IndexPage
