import React from "react"
import { SectionStyle2 } from "../components/sofbox"
import { useTranslation } from "react-i18next"
import Check from "react-ionicons/lib/IosCheckmarkCircleOutline"
// import VeggieMockup from "src/static/assets/images/Queue/VeggieMockup.png"
import { Parallax, Background } from "react-parallax"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

const Index = () => {
  const data = useStaticQuery(graphql`
    query {
      veggie: file(relativePath: { eq: "Veggie2.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fixed(width: 1476) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `)

  const { t } = useTranslation()
  return (
    <>
      <Parallax strength={300} style={{ paddingBottom: 100 }}>
        <Background>
          <Img objectFit="cover" fixed={data.veggie.childImageSharp.fixed} />
        </Background>
        <SectionStyle2 ids="software-features" small={true}>
          <div
            className="col-lg-8 offset-lg-2 darko-bg"
            style={{
              borderRadius: 25,
              display: "inline-block",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: 25,
            }}
          >
            <div className="heading-title white text-center">
              <h3 className="iq-tw-7 iq-mb-25 iq-font-white title">
                {t("features-label")}
              </h3>
            </div>
            <ul className="iq-mt-40 iq-list">
              <li className="iq-tw-6 iq-mb-15" style={{ paddingBottom: 15 }}>
                <i style={{ width: 50, height: 50, paddingRight: 10 }}>
                  <Check color="#f0b660" />
                </i>
                <h4 className="iq-font-white">{t("customer-management")}</h4>
              </li>
              <li className="iq-tw-6 iq-mb-15" style={{ paddingBottom: 15 }}>
                <i style={{ width: 50, height: 50, paddingRight: 10 }}>
                  <Check color="#f0b660" />
                </i>
                <h4 className="iq-font-white">{t("inventory")}</h4>
              </li>
              <li className="iq-tw-6 iq-mb-15" style={{ paddingBottom: 15 }}>
                <i style={{ width: 50, height: 50, paddingRight: 10 }}>
                  <Check color="#f0b660" />
                </i>
                <h4 className="iq-font-white">
                  {t("multiple-payment-systems")}
                </h4>
              </li>
              <li className="iq-tw-6 iq-mb-15" style={{ paddingBottom: 15 }}>
                <i style={{ width: 50, height: 50, paddingRight: 10 }}>
                  <Check color="#f0b660" />
                </i>
                <h4 className="iq-font-white">{t("employee-management")}</h4>
              </li>
              <li className="iq-tw-6 iq-mb-15" style={{ paddingBottom: 15 }}>
                <i style={{ width: 50, height: 50, paddingRight: 10 }}>
                  <Check color="#f0b660" />
                </i>
                <h4 className="iq-font-white">{t("language-options")}</h4>
              </li>
            </ul>
          </div>
        </SectionStyle2>
      </Parallax>
    </>
  )
}

export default Index
