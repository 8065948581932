import React from "react"
import Navbar2 from "../components/Navbar2"
import InternetHelp from "../components/InternetHelp"
import { I18nextProvider } from "react-i18next"
import i18n from "../i18n/config"
import "../static/style.css"

const Internet = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <Navbar2 />
      <InternetHelp />
    </I18nextProvider>
  )
}

export default Internet
