import React from "react"
import { useTranslation } from "react-i18next"
import Logo from "src/static/assets/images/favicon.ico"
import i18next from "i18next"
import { Link } from "react-scroll"
import { Link as ReachLink } from "@reach/router"

//import { Navbar, Nav, Button } from "react-bootstrap"
import { Navbar, Nav, NavDropdown, Tabs, Tab } from "react-bootstrap"

const CustomNavbar = () => {
  const { t } = useTranslation()

  return (
    <>
      <Navbar
        variant="light"
        expand="lg"
        id="site-navbar"
        fixed="top"
        bg="light"
        collapseOnSelect
      >
        <Link
          to="main-home"
          className="link-no-style"
          spy
          smooth
          offset={-70}
          duration={500}
        >
          <Navbar.Brand as="span">
            <img src={Logo} width="50" height="50" id="iq-home" alt="null" />{" "}
            QUEUE Technology
          </Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Link
              to="what_can_do"
              className="link-no-style"
              spy
              smooth
              offset={-70}
              duration={500}
            >
              <Nav.Link as="span" eventKey="page-2">
                {t("about")}
              </Nav.Link>
            </Link>
            <Link
              to="software-features"
              className="link-no-style"
              spy
              smooth
              offset={-70}
              duration={500}
            >
              <Nav.Link as="span" eventKey="page-2">
                {t("features-label")}
              </Nav.Link>
            </Link>
            <Link
              to="pricing"
              className="link-no-style"
              spy
              smooth
              offset={-70}
              duration={500}
            >
              <Nav.Link as="span" eventKey="page-2">
                {t("pricing-label")}
              </Nav.Link>
            </Link>
            <Link
              to="faq"
              className="link-no-style"
              spy
              smooth
              offset={-70}
              duration={500}
            >
              <Nav.Link as="span" eventKey="page-2">
                {t("faq-label")}
              </Nav.Link>
            </Link>
            <Link
              to="footer"
              className="link-no-style"
              spy
              smooth
              offset={-70}
              duration={500}
            >
              <Nav.Link as="span" eventKey="page-2">
                {t("contact")}
              </Nav.Link>
            </Link>
            <Nav.Link
              href="https://admin.qpos.me"
              target="_blank"
              rel="noreferrer"
            >
              Admin
            </Nav.Link>
            <Nav.Link target="_blank" rel="noreferrer">
              <ReachLink to="/help/" style={{ color: "gray" }}>
                {t("nav-2")}
              </ReachLink>
            </Nav.Link>
            <NavDropdown title={t("nav-1")}>
              <NavDropdown.Item className="dropdown-item" collapseOnSelect>
                <ReachLink to="/hardware/" style={{ color: "gray" }}>
                  {t("nav-3")}
                </ReachLink>
              </NavDropdown.Item>
              <NavDropdown.Item
                className="dropdown-item"
                href="https://forms.gle/vxdr2kM85HGWk5Dw5"
                target="_blank"
                style={{ color: "gray" }}
              >
                {t("nav-4")}
              </NavDropdown.Item>
              <NavDropdown.Item className="dropdown-item" collapseOnSelect>
                <ReachLink to="/service/" style={{ color: "gray" }}>
                  {t("nav-5")}
                </ReachLink>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav>
            <NavDropdown
              title={t("language-nav")}
              id="langauge-dropdown"
              collapseOnSelect
            >
              <NavDropdown.Item
                className="dropdown-item"
                onClick={() => i18next.changeLanguage("zh-TW")}
                collapseOnSelect
              >
                {t("button-tw")}
              </NavDropdown.Item>
              <NavDropdown.Item
                className="dropdown-item"
                onClick={() => i18next.changeLanguage("en")}
                collapseOnSelect
              >
                {t("button-en")}
              </NavDropdown.Item>
            </NavDropdown>
            <Link
              to="footer"
              className="link-no-style"
              spy
              smooth
              offset={-70}
              duration={500}
            >
              <Nav.Link as="span" eventKey="page-2">
                {t("contact")}
              </Nav.Link>
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  )
}

export default CustomNavbar
