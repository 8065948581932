import React from "react"
import { useTranslation } from "react-i18next"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Download from "../components/Download"

const Home = () => {
  const data = useStaticQuery(graphql`
    query {
      queue: file(relativePath: { eq: "QUEUEPos.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      landing: file(relativePath: { eq: "landing.png" }) {
        childImageSharp {
          fluid(maxWidth: 752) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const { t } = useTranslation()

  return (
    <div id="main-home">
      <section id="iq-home" className="iq-banner-03  dark-bg">
        <div className="container-fluid" style={{ paddingTop: 50 }}>
          <div className="banner-text text-left">
            <div className="row">
              <div className="col-lg-6">
                <Img
                  fluid={data.queue.childImageSharp.fluid}
                  style={{ maxWidth: 500 }}
                />

                <h3 className="iq-font-white iq-mtb-20">{t("intro")}</h3>
                <Download />
              </div>
              <div className="col-lg-6">
                <Img
                  fluid={data.landing.childImageSharp.fluid}
                  style={{ maxWidth: 750 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Home
