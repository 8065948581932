import React, { useState, useEffect } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { useTranslation } from "react-i18next"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const SwipeToSlide = () => {
  const data = useStaticQuery(graphql`
    query {
      customer1: file(relativePath: { eq: "customer1.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      customer2: file(relativePath: { eq: "customer2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      customer3: file(relativePath: { eq: "customer3.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      customer4: file(relativePath: { eq: "customer4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      customer5: file(relativePath: { eq: "customer5.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      customer6: file(relativePath: { eq: "customer6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      customer7: file(relativePath: { eq: "customer7.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      customer8: file(relativePath: { eq: "customer8.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      customer9: file(relativePath: { eq: "customer9.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      customer10: file(relativePath: { eq: "customer10.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      customer11: file(relativePath: { eq: "customer11.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      customer12: file(relativePath: { eq: "customer12.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      customer13: file(relativePath: { eq: "customer13.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      customer14: file(relativePath: { eq: "customer14.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      customer15: file(relativePath: { eq: "customer15.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      customer16: file(relativePath: { eq: "customer16.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      customer17: file(relativePath: { eq: "customer17.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      customer18: file(relativePath: { eq: "customer18.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      customer19: file(relativePath: { eq: "customer19.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      customer20: file(relativePath: { eq: "customer20.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  const { t } = useTranslation()
  const [isRendered, setIsRendered] = useState(false)
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    autoplay: false,
    slidesToShow: 9,
    speed: 500,
    rows: 1,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  }
  useEffect(() => {
    setIsRendered(true)
  }, [])

  if (!isRendered) {
    return <h3>Loading...</h3>
  } else
    return (
      <div
        className="dark-bg white text-center"
        style={{ paddingTop: 10, paddingBottom: 10 }}
      >
        <h2 className="iq-tw-7 iq-mb-25 iq-font-white title">
          {t("our-clients")}
        </h2>
        <Slider {...settings} className="dark-bg" style={{ paddingBottom: 30 }}>
          <div>
            <Img
              fluid={data.customer1.childImageSharp.fluid}
              imgStyle={{ margin: 5 }}
            />
          </div>
          <div>
            <Img
              fluid={data.customer2.childImageSharp.fluid}
              imgStyle={{ margin: 5 }}
            />
          </div>
          <div>
            <Img
              fluid={data.customer3.childImageSharp.fluid}
              imgStyle={{ margin: 5 }}
            />
          </div>
          <div>
            <Img
              fluid={data.customer4.childImageSharp.fluid}
              imgStyle={{ margin: 5 }}
            />
          </div>
          <div>
            <Img
              fluid={data.customer5.childImageSharp.fluid}
              imgStyle={{ margin: 5 }}
            />
          </div>
          <div>
            <Img
              fluid={data.customer6.childImageSharp.fluid}
              imgStyle={{ margin: 5 }}
            />
          </div>
          <div>
            <Img
              fluid={data.customer7.childImageSharp.fluid}
              imgStyle={{ margin: 5 }}
            />
          </div>
          <div>
            <Img
              fluid={data.customer8.childImageSharp.fluid}
              imgStyle={{ margin: 5 }}
            />
          </div>
          <div>
            <Img
              fluid={data.customer9.childImageSharp.fluid}
              imgStyle={{ margin: 5 }}
            />
          </div>
          <div>
            <Img
              fluid={data.customer10.childImageSharp.fluid}
              imgStyle={{ margin: 5 }}
            />
          </div>
          <div>
            <Img
              fluid={data.customer11.childImageSharp.fluid}
              imgStyle={{ margin: 5 }}
            />
          </div>
          <div>
            <Img
              fluid={data.customer12.childImageSharp.fluid}
              imgStyle={{ margin: 5 }}
            />
          </div>
          <div>
            <Img
              fluid={data.customer13.childImageSharp.fluid}
              imgStyle={{ margin: 5 }}
            />
          </div>
          <div>
            <Img
              fluid={data.customer14.childImageSharp.fluid}
              imgStyle={{ margin: 5 }}
            />
          </div>
          <div>
            <Img
              fluid={data.customer15.childImageSharp.fluid}
              imgStyle={{ margin: 5 }}
            />
          </div>
          <div>
            <Img
              fluid={data.customer16.childImageSharp.fluid}
              imgStyle={{ margin: 5 }}
            />
          </div>
          <div>
            <Img
              fluid={data.customer17.childImageSharp.fluid}
              imgStyle={{ margin: 5 }}
            />
          </div>
          <div>
            <Img
              fluid={data.customer18.childImageSharp.fluid}
              imgStyle={{ margin: 5 }}
            />
          </div>
          <div>
            <Img
              fluid={data.customer19.childImageSharp.fluid}
              imgStyle={{ margin: 5 }}
            />
          </div>
          <div>
            <Img
              fluid={data.customer20.childImageSharp.fluid}
              imgStyle={{ margin: 5 }}
            />
          </div>
        </Slider>
      </div>
    )
}

export default SwipeToSlide
