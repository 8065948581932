import React from "react"
import { useTranslation } from "react-i18next"
import { PricingCardStyle2 } from "../components/sofbox"
import Check from "react-ionicons/lib/IosCheckboxOutline"

import List from "react-ionicons/lib/IosListBoxOutline"
import Monitor from "react-ionicons/lib/IosDesktopOutline"
import Money from "react-ionicons/lib/LogoUsd"

const Pricing = () => {
  const { t } = useTranslation()
  return (
    <>
      <section
        id="pricing"
        className="grey-bg iq-price-table"
        style={{ paddingTop: 50, paddingBottom: 50 }}
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="heading-title">
                <h3 className="title iq-tw-7">{t("pricing-label")}</h3>
              </div>
            </div>
          </div>

          <div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div
                className="col-lg-6 wow border flipInY r4-mt-30 iq-pricing pricing-03 text-center"
                data-wow-duration="1s"
              >
                <PricingCardStyle2
                  title={
                    <>
                      <small>$</small>11,900
                      <small>{t("annual-plan")}</small>
                      <br></br>
                      <small>$</small>900
                      <small>{t("avg-monthly")}</small>
                    </>
                  }
                  subTitle={t("no-invoice-price")}
                >
                  <ul
                    className="text-left iq-font-white dark-bg"
                    style={{
                      paddingLeft: 30,
                      paddingRight: 30,
                    }}
                  >
                    <li className="iq-services-box">
                      <Check
                        color="#f0b660"
                        style={{ width: 40, height: 40, paddingRight: 10 }}
                      />

                      {t("no-invoice1")}
                    </li>
                    <li>
                      <Check
                        color="#f0b660"
                        style={{ width: 40, height: 40, paddingRight: 10 }}
                      />
                      {t("no-invoice2")}
                    </li>
                    <li>
                      <Check
                        color="#f0b660"
                        style={{ width: 40, height: 40, paddingRight: 10 }}
                      />
                      {t("no-invoice3")}
                    </li>
                    <li>
                      <Check
                        color="#f0b660"
                        style={{ width: 40, height: 40, paddingRight: 10 }}
                      />
                      {t("no-invoice4")}
                    </li>
                  </ul>
                </PricingCardStyle2>
              </div>

              <div
                className="col-lg-6 border wow flipInY r4-mt-30 iq-pricing pricing-03 text-center"
                data-wow-duration="1s"
              >
                <PricingCardStyle2
                  bgImage={""}
                  title={
                    <>
                      <small>$</small>22,000
                      <small>{t("annual-plan")}</small>
                      <br></br>
                      <small>$</small>1,833
                      <small>{t("avg-monthly")}</small>
                    </>
                  }
                  subTitle={t("invoice-price")}
                >
                  <ul
                    className="text-left dark-bg iq-font-white"
                    style={{ paddingLeft: 30 }}
                  >
                    <li>
                      <Check
                        color="#f0b660"
                        style={{ width: 40, height: 40, paddingRight: 10 }}
                      />
                      {t("e-invoice1")}
                    </li>
                    <li>
                      <Check
                        color="#f0b660"
                        style={{ width: 40, height: 40, paddingRight: 10 }}
                      />
                      {t("e-invoice2")}
                    </li>
                    <li>
                      <Check
                        color="#f0b660"
                        style={{ width: 40, height: 40, paddingRight: 10 }}
                      />
                      {t("e-invoice3")}
                    </li>
                    <li>
                      <Check
                        color="#f0b660"
                        style={{ width: 40, height: 40, paddingRight: 10 }}
                      />
                      {t("e-invoice4")}
                    </li>
                  </ul>
                </PricingCardStyle2>
              </div>

              <div
                className="col-lg-12 border mx-auto wow flipInY r4-mt-30 iq-pricing pricing-03"
                style={{ paddingBottom: 50 }}
                data-wow-duration="1s"
              >
                <PricingCardStyle2
                  className="mx-auto"
                  bgImage={""}
                  title={
                    <div>
                      {t("add-on-label")}
                      <br />
                    </div>
                  }
                >
                  <ul className="text-left iq-font-white dark-bg text-center">
                    <li>
                      <List
                        color="#f0b660"
                        style={{ width: 40, height: 40, paddingRight: 10 }}
                      />
                      {t("add-on-1")}
                    </li>
                    <li>
                      <Monitor
                        color="#f0b660"
                        style={{ width: 40, height: 40, paddingRight: 10 }}
                      />
                      {t("add-on-2")}
                    </li>
                    <li>
                      <Money
                        color="#f0b660"
                        style={{ width: 40, height: 40, paddingRight: 10 }}
                      />
                      {t("add-on-3")}
                    </li>
                  </ul>
                </PricingCardStyle2>
              </div>
              <div style={{ paddingBottom: 50 }}></div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Pricing
