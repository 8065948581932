import React from "react"

const Index = (props) => {
  return (
    <>
      <section id={props.ids} className="overview-block-ptb how-works">
        <div className="container">
          <div className={"row"}>
            {props.children}

            <div
              className={
                props.small ? "iq-objects-software" : "iq-objects-softwarenew"
              }
            ></div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Index
