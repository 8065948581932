import React from "react"

const Index = (props) => {
  return (
    <>
      <div className={"heading-title" + props.className}>
        <h3 className="title iq-font-white iq-tw-7">{props.title}</h3>
        <h4 className={props.pClassName}>{props.subTitle}</h4>
      </div>
    </>
  )
}

export default Index
