import React from "react"

const Index = (props) => {
  const { className, ids, bgImage } = props
  return (
    <>
      <div
        id={ids}
        data-parallax="true"
        className={className}
        style={{ backgroundImage: "url(" + bgImage + ")" }}
      >
        {props.children}
      </div>
    </>
  )
}

export default Index
