import React from "react"
import Location from "react-ionicons/lib/IosNavigateOutline"
import Mail from "react-ionicons/lib/IosMailOutline"
import Twitter from "react-ionicons/lib/LogoTwitter"
import Facebook from "react-ionicons/lib/LogoFacebook"
import Instagram from "react-ionicons/lib/LogoInstagram"
import { useTranslation } from "react-i18next"
import Chat from "react-ionicons/lib/IosChatbubblesOutline"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Footer = () => {
  const { t } = useTranslation()
  const data = useStaticQuery(graphql`
    query {
      line: file(relativePath: { eq: "LINE2.jpg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fixed(width: 150, height: 150) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <>
      <section className="footer-info iq-pt-100" id="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <div className="iq-footer-box text-left">
                <div className="iq-icon">
                  <i aria-hidden="true">
                    <Location />
                  </i>
                </div>
                <div className="footer-content">
                  <h4 className="iq-tw-7 iq-pb-10">{t("address-label")}</h4>
                  <p>{t("address")}</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 r-mt-30">
              <div className="iq-footer-box text-left">
                <div className="iq-icon">
                  <i aria-hidden="true">
                    <a href="mailto: contact@queueme.co">
                      <Mail />
                    </a>
                  </i>
                </div>
                <div className="footer-content">
                  <h4 className="iq-tw-7 iq-pb-10">{t("mail")}</h4>
                  <p>{t("e-mail")}</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 r-mt-30">
              <div className="iq-footer-box text-left">
                <div className="iq-icon">
                  <i aria-hidden="true">
                    <Chat />
                  </i>
                </div>
                <div className="footer-content">
                  <h4 className="iq-tw-7 iq-pb-10">{t("line")}</h4>
                  <p>{t("line-c")}</p>

                  <a href="https://line.me/R/ti/p/@kws2842e">
                    <Img fixed={data.line.childImageSharp.fixed} />{" "}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 r-mt-30">
              <div className="iq-footer-box text-left">
                <div className="iq-icon">
                  <i aria-hidden="true">
                    <a href="https://twitter.com/queuemeco">
                      <Twitter />
                    </a>
                  </i>
                </div>
                <div className="footer-content">
                  <h4 className="iq-tw-7 iq-pb-10">{t("twitter")}</h4>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 r-mt-30">
              <div className="iq-footer-box text-left">
                <div className="iq-icon">
                  <i aria-hidden="true">
                    <a href="https://www.facebook.com/queuemeco">
                      <Facebook />
                    </a>
                  </i>
                </div>
                <div className="footer-content">
                  <h4 className="iq-tw-7 iq-pb-10">{t("facebook")}</h4>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 r-mt-30">
              <div className="iq-footer-box text-left">
                <div className="iq-icon">
                  <i aria-hidden="true">
                    <a href="https://www.instagram.com/queuepos/">
                      <Instagram />
                    </a>
                  </i>
                </div>
                <div className="footer-content">
                  <h4 className="iq-tw-7 iq-pb-10">{t("instagram")}</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="row iq-mt-40">
            <div className="col-sm-12 text-center">
              <div className="footer-copyright iq-ptb-20">
                © <span id="copyright"> 2020 </span>
                <a href="/" className="text-green">
                  Queue Technology
                </a>{" "}
                All Rights Reserved
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Footer
