import React from "react"
import { SectionTitle } from "../components/sofbox"
import { useTranslation } from "react-i18next"
import IPhone from "react-ionicons/lib/IosPhonePortrait"
import Cloud from "react-ionicons/lib/IosCloudOutline"
import Trending from "react-ionicons/lib/IosTrendingUp"
import Print from "react-ionicons/lib/IosPrintOutline"
import Banner from "src/static/assets/images/banner/06lgu.png"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Index = (props) => {
  const { t } = useTranslation()

  const cardData = [
    {
      icon: <IPhone color="#f0b660" />,
      title: t("compatibility-label"),
      description: t("compatibility") + t("compatibility2"),
    },
    {
      icon: <Cloud color="#f0b660" />,
      title: t("sync-label"),
      description: t("sync"),
    },
    {
      icon: <Print color="#f0b660" />,
      title: t("printing-label"),
      description: t("printing"),
    },
    {
      icon: <Trending color="#f0b660" />,
      title: t("tracking-label"),
      description: t("tracking"),
    },
  ]

  const data = useStaticQuery(graphql`
    query {
      mockup: file(relativePath: { eq: "QUEUEMockup.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 617) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <div>
        <section id="what_can_do" className="how-works grey-bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="heading-title left text-left"
                  style={{ paddingTop: 50 }}
                >
                  <h3 className="iq-tw-7 iq-mb-25 title">
                    {t("what-is-queue")}
                  </h3>
                </div>
                <br />
                <div className="text-discover iq-mb-25 ">
                  <ul>
                    <li>
                      <h4>{t("queue-explain-1")}</h4>
                    </li>
                    <br />
                    <li>
                      <h4>{t("queue-explain-2")}</h4>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6">
                <Img fluid={data.mockup.childImageSharp.fluid} />
              </div>
            </div>
            <div className="iq-objects grey-bg">
              <span className="iq-objects-02 iq-fadebounce">
                <span className="iq-round" />
              </span>
            </div>
          </div>
        </section>
        <div className="grey-bg">
          <img src={Banner} className="banner-img grey-bg" alt="null" />
        </div>
        <section
          id="how-it-works"
          className="dark-bg overview-block-ptb white text-center"
        >
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <SectionTitle title={t("sets-us-apart")} />
              </div>
            </div>
            <div
              className="row justify-content-between"
              style={{ paddingTop: 40 }}
            >
              {cardData.map((card, index) => (
                <div key={index} className="col-sm-6 col-md-6 col-lg-3 ">
                  <div
                    className="iq-works-box round-icon text-center"
                    style={{ borderRadius: 30 }}
                  >
                    <div className="icon-bg center-block">{card.icon}</div>
                    <h5 className="iq-font-white iq-tw-7 iq-mt-25 iq-mb-15">
                      {card.title}
                    </h5>
                    <p className="iq-font-white">{card.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default Index
