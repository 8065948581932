import React, { useState, useEffect } from "react"
import Slider from "react-slick"
import { useTranslation } from "react-i18next"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Parallax, Background } from "react-parallax"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Testimonial = () => {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "coffeephone1.png" }) {
        childImageSharp {
          fixed(width: 1500, height: 1000) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      wideawake: file(relativePath: { eq: "Wide-Awake.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fixed(width: 250, height: 250) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      elsabraso: file(relativePath: { eq: "el-sabraso.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fixed(width: 250, height: 250) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      bistro: file(relativePath: { eq: "rubenbistro.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fixed(width: 250, height: 250) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `)
  const { t } = useTranslation()
  const [isRendered, setIsRendered] = useState(false)
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    centerMode: true,
    autoplay: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  useEffect(() => {
    setIsRendered(true)
  }, [])

  if (!isRendered) {
    return <h3>Loading...</h3>
  } else
    return (
      <Parallax strength={100}>
        <Background>
          <Img
            imgStyle={{
              objectFit: "none",
            }}
            style={{ display: "flex", alignItems: "flex-start" }}
            fixed={data.background.childImageSharp.fixed}
          />
        </Background>
        <div
          className="heading-title white text-center"
          style={{ paddingTop: 60 }}
        >
          <div
            className="darko2-bg"
            style={{ paddingBottom: 40, paddingTop: 40, borderRadius: 25 }}
          >
            <h2
              className="iq-tw-7 iq-mb-25 iq-font-white title"
              style={{
                display: "inline-block",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {t("reviews")}
            </h2>
            <Slider
              {...settings}
              style={{ paddingBottom: 60, margin: 40, borderRadius: 30 }}
            >
              <div
                className="card"
                style={{ height: 300, padding: 10, margin: 20 }}
              >
                <div style={{ paddingTop: 30 }}>
                  <Img fixed={data.wideawake.childImageSharp.fixed} />
                </div>
                <div className="card-body" style={{ height: 350 }}>
                  <h3 className="card-title">{t("testimonial-n1")}</h3>
                  <p className="card-text">{t("testimonial-1")}</p>
                </div>
              </div>
              <div
                className="card"
                style={{ height: 300, padding: 10, margin: 20 }}
              >
                <div style={{ paddingTop: 30 }}>
                  <Img fixed={data.bistro.childImageSharp.fixed} />
                </div>
                <div className="card-body" style={{ height: 350 }}>
                  <h3 className="card-title">{t("testimonial-n2")}</h3>
                  <p className="card-text">{t("testimonial-2")}</p>
                </div>
              </div>
              <div
                className="card"
                style={{ height: 300, padding: 10, margin: 20 }}
              >
                <div style={{ paddingTop: 30 }}>
                  <Img fixed={data.elsabraso.childImageSharp.fixed} />
                </div>
                <div className="card-body" style={{ height: 350 }}>
                  <h3 className="card-title">{t("testimonial-n3")}</h3>
                  <p className="card-text">{t("testimonial-3")}</p>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </Parallax>
    )
}

export default Testimonial
