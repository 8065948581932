import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Index = (props) => {
  const data = useStaticQuery(graphql`
    query {
      download1: file(relativePath: { eq: "app-store.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      download2: file(relativePath: { eq: "download2.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-6">
            <a
              href="https://itunes.apple.com/tw/app/queue/id1282518354?mt=8"
              target="_blank"
              rel="noreferrer"
            >
              <Img
                fluid={data.download1.childImageSharp.fluid}
                className="img-fluid"
                style={{ maxWidth: 275 }}
              />
            </a>
          </div>
          <div className="col-6">
            <a
              href="https://play.google.com/store/apps/details?id=com.queuepos&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              target="_blank"
              rel="noreferrer"
            >
              <Img
                fluid={data.download2.childImageSharp.fluid}
                className="img-fluid"
                style={{ maxWidth: 275 }}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Index
