import React from "react"
import Navbar2 from "../components/Navbar2"
import SettingsHelp from "../components/SettingsHelp"
import { I18nextProvider } from "react-i18next"
import i18n from "../i18n/config"
import "../static/style.css"

const Settings = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <Navbar2 />
      <SettingsHelp />
    </I18nextProvider>
  )
}

export default Settings
