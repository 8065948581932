import React from "react"
import { Jumbotron, Button } from "react-bootstrap"
import { useTranslation } from "react-i18next"

const SettingsHelp = () => {
  const { t } = useTranslation()
  return (
    <div>
      <br />
      <Jumbotron>
        <h1>
          Use our step-by-step guild to figure identify the various settings
        </h1>
        <p>
          Follow these simple steps in order to accurately detect any printer
          troubles you might encounter.
        </p>
        <ul>
          <li>{t("print-1")}</li>
          <li>{t("print-2")}</li>
          <li>Step 3</li>
          <li>Step 4</li>
          <li>Step 5</li>
        </ul>
        <Button variant="primary">Learn more</Button>
      </Jumbotron>
    </div>
  )
}

export default SettingsHelp
