import React from "react"
// import MessengerCustomerChat from "react-messenger-customer-chat"
import loadable from "@loadable/component"

const MessengerCustomerChat = loadable(() =>
  import("react-messenger-customer-chat")
)

const Messenger = () => {
  return (
    <>
      <MessengerCustomerChat pageId="152126105600531" appId="642919149649987" />
    </>
  )
}

export default Messenger
