import React from "react"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import { useTranslation } from "react-i18next"
import "react-accessible-accordion/dist/fancy-example.css"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Faq = () => {
  const { t } = useTranslation()
  const data = useStaticQuery(graphql`
    query {
      photo2: file(relativePath: { eq: "photo2.jpg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 600, grayscale: true) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      photo1: file(relativePath: { eq: "photo1.jpg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 600, grayscale: true) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      photo3: file(relativePath: { eq: "photo3.jpg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 600, grayscale: true) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      photo4: file(relativePath: { eq: "photo4.jpg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 600, grayscale: true) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <div id="faq" className="row align-items-center dark-bg overview-block-ptb">
      <div className="col-sm-12">
        <div className="heading-title white">
          <h3 className="title iq-font-white iq-tw-7">{t("faq-1")}</h3>
        </div>
      </div>

      <div
        className="col-lg-6 col-sm-12 text-center"
        style={{ display: "block", padding: 10 }}
      >
        <Img fluid={data.photo2.childImageSharp.fluid} />
      </div>
      <div
        className="col-lg-6 col-sm-12 text-center"
        style={{ display: "block", padding: 10 }}
      >
        <Img fluid={data.photo1.childImageSharp.fluid} />
      </div>
      <div
        className="col-lg-6 col-sm-12 text-center"
        style={{ display: "block", padding: 10 }}
      >
        <Img fluid={data.photo3.childImageSharp.fluid} />
      </div>
      <div
        className="col-lg-6 col-sm-12 text-center"
        style={{ display: "block", padding: 10 }}
      >
        <Img fluid={data.photo4.childImageSharp.fluid} />
      </div>

      <div className="col-lg-12" style={{ paddingTop: 20 }}>
        <Accordion allowZeroExpanded="true">
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>{t("faq-1a")}</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <h5 className="iq-font-white">{t("faq-1b")}</h5>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>{t("faq-8a")}</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <h5 className="iq-font-white">{t("faq-8b")}</h5>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>{t("faq-2a")}</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <h5 className="iq-font-white">{t("faq-2b")}</h5>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>{t("faq-3a")}</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <h5 className="iq-font-white">{t("faq-3b")}</h5>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>{t("faq-4a")}</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <h5 className="iq-font-white">{t("faq-4b-1")}</h5>
              <br />
              <h3
                className="iq-font-white"
                style={{ fontWeight: "bolder", textDecoration: "underline" }}
              >
                {t("faq-4b-2")}
              </h3>
              <div className="iq-font-white">
                <h6 className="iq-font-white" style={{ fontWeight: "bolder" }}>
                  {t("faq-4b-3a")}
                </h6>
                {t("faq-4b-3")}
              </div>
              <br />
              <div className="iq-font-white">
                <h6 className="iq-font-white" style={{ fontWeight: "bolder" }}>
                  {t("faq-4b-4a")}
                </h6>
                {t("faq-4b-4")}
              </div>
              <br />
              <h3
                className="iq-font-white"
                style={{ fontWeight: "bolder", textDecoration: "underline" }}
              >
                {t("faq-4b-5")}
              </h3>
              <h6 className="iq-font-white">{t("faq-4b-6")}</h6>
              <br />
              <h3
                className="iq-font-white"
                style={{ fontWeight: "bolder", textDecoration: "underline" }}
              >
                {t("faq-4b-7")}
              </h3>
              <div className="iq-font-white">
                <h6 className="iq-font-white" style={{ fontWeight: "bolder" }}>
                  {t("faq-4b-8a")}
                </h6>
                {t("faq-4b-8")}
              </div>
              <br />
              <div className="iq-font-white">
                <h6 className="iq-font-white" style={{ fontWeight: "bolder" }}>
                  {t("faq-4b-9a")}
                </h6>
                {t("faq-4b-9")}
              </div>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>{t("faq-5a")}</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <h5 className="iq-font-white">{t("faq-5b")}</h5>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>{t("faq-6a")}</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <h5 className="iq-font-white">{t("faq-6b")}</h5>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>{t("faq-7a")}</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <h5 className="iq-font-white">{t("faq-7b")}</h5>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  )
}

export default Faq
