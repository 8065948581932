import React from "react"
import loadable from "@loadable/component"

const Map = loadable(() => import("./Map"))

const Contact = () => {
  return <Map />
}

export default Contact
